import { BaseDto } from '@/shared/dtos/base-dto';

export class configuracion_paciente_app extends BaseDto {
    public numero !: number;
    public paciente_nombre !: string;
    public paciente_apellidos !: string;
    public email !: string;
    public Telefono1 !: string;
    public tratamiento_terminado !: boolean;
    public nutricionista !: string;
    public nutri_nombre !: string;
    public nutri_apellidos !: string;
    public nutri_empresa !: string;
    public nutri_color_primario !: string;
    public nutri_color_secundario !: string;

 } 

export class ThemeConstants {
  public static PRIMARY_COLOR: string = '';
  public static SECONDARY_COLOR: string = '';
  public static LIGHTER_COLOR: string = '';
  public static DARKER_COLOR: string = '';
  public static RGB: string = '';


  public static hexToRgb(hex: any) {
    try {
      // Convierte un color HEX a RGB
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return `${r}, ${g}, ${b}`;
    } catch (error) {
      return "";
    }

  }


  public static getLighterColor(hex: any, factor = 1.2) {
    try {
      // Convierte HEX a RGB
      let bigint = parseInt(hex.replace("#", ""), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;

      // Aumenta el brillo multiplicando por el factor (máximo 255)
      r = Math.min(255, Math.floor(r * factor));
      g = Math.min(255, Math.floor(g * factor));
      b = Math.min(255, Math.floor(b * factor));

      // Convierte de nuevo a HEX
      return `rgb(${r}, ${g}, ${b})`;
    } catch (error) {
      return "";
    }
  }
}

